<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

const props = defineProps<{
  name?: string
  reason?: string
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['DialogPart11Info']['contextData']): void
}>()

const { t } = useI18n()

const info = reactive({
  name: props.name ?? '',
  reason: props.reason ?? '',
})

const isValid = ref(false)
</script>

<template>
  <base-dialog
    data-cy="part11_info_dialog"
    max-width="700"
    :title="t('dialog.part11_info.title')"
    :action-text="t('dialog.part11_info.action_text')"
    :cancel-text="t('dialog.part11_info.view_document')"
    :loading="loading"
    :disabled="!isValid"
    @close="emit('close')"
    @action="emit('action', { ...info })"
  >
    <div>
      <div class="d-flex align-center mb-6">
        <p class="flex-grow-1 | description">{{ t('dialog.part11_info.text') }}</p>
        <responsive-image source="sign-contract" :width="165" :height="119" />
      </div>
      <v-form v-model="isValid">
        <text-input
          v-model="info.name"
          data-cy="name_input"
          :label="t('dialog.part11_info.input.name.label')"
          required
        />
        <text-input
          v-model="info.reason"
          data-cy="reason_input"
          :label="t('dialog.part11_info.input.reason.label')"
          :rules="[value => value.length <= 250 || `${value.length} / 250`]"
          required
        />
      </v-form>
    </div>
  </base-dialog>
</template>

<style lang="sass" scoped>
.description
  font-size: 20px
  line-height: 1.5
</style>
